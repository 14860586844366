import Vue from 'vue';
import EventModelBase from '../_generated/modelBases/event-model-base';

export default class EventModel extends EventModelBase {
  static get CUSTOMER_GROUP_NUMBER_MEMBER() { return Vue.$config.values['widget-event-customergroupnumbermember']; }

  static get CUSTOMER_GROUP_NUMBER_NON_MEMBER() { return Vue.$config.values['widget-event-customergroupnumbernonmember']; }

  getPrice(group: number | undefined | null): number | undefined {
    if (group === undefined || group === null) {
      return undefined;
    }
    return this.priceItems.find((i) => i.customerGroupNumber === group)?.price;
  }

  get isFree(): boolean {
    return this.priceItems.length === 0 || this.priceItems.every((i) => i.price === 0);
  }

  // TODO: This needs to be in the backend: CustomerGroup would not need to be kept in the Frontend
  // like this if the summed prices from the backend also contained the customerGroupName in some form
  // additionally marking it as member/non-member
  get customerGroupSettings() {
    return {
      [EventModel.CUSTOMER_GROUP_NUMBER_MEMBER]: {
        id: EventModel.CUSTOMER_GROUP_NUMBER_MEMBER,
        caption: Vue.$t('kfmv.widget.eventregistration.priceselectionOptions.memberprice'),
      },
      [EventModel.CUSTOMER_GROUP_NUMBER_NON_MEMBER]: {
        id: EventModel.CUSTOMER_GROUP_NUMBER_NON_MEMBER,
        caption: Vue.$t('kfmv.widget.eventregistration.priceselectionOptions.notmemberprice'),
      },
    } as Record<any, { caption: string, id: number }>;
  }

  get memberPriceItem() {
    return this.priceItems.find((item) => item.customerGroupNumber === EventModel.CUSTOMER_GROUP_NUMBER_MEMBER);
  }

  get nonMemberPriceItem() {
    return this.priceItems.find((item) => item.customerGroupNumber === EventModel.CUSTOMER_GROUP_NUMBER_NON_MEMBER);
  }

  get priceItems(): { customerGroupNumber: number | undefined, price: number, currency: string, caption: string }[] {
    // KFMV-Event registration only has a single partyRole (as defined by Customer)
    // This needs to be refactored to support multiple partyRoles
    const [priceForParticipant] = (this.totalPrices ?? []);
    const prices = priceForParticipant?.customerGroupTotalPrices ?? {};

    return Object.entries(prices).map(([group, price]) => {
      const groupCaption = this.customerGroupSettings[group]?.caption ?? 'UNKNOWN CUSTOMER GROUP';
      const currency = Vue.$config.values['widget-defaultcurrency'] ?? 'CHF';
      return {
        customerGroupNumber: group !== undefined ? parseInt(group, 10) : undefined,
        price,
        currency,
        caption: `${groupCaption}: ${price} ${currency}`,
      };
    });
  }
}
