// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import EventModel from '../../model/event-model';
import EventDTO from '../dto/event-dto';
import dtoSchema from '../schemas/event-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import EventPriceSummaryModel from '../../model/event-price-summary-model';

export default abstract class EventModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<EventDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.eventNumber = data.eventNumber;
      this.businessUnitNumber = data.businessUnitNumber;
      this.eventName = data.eventName ?? undefined;
      this.description = data.description ?? undefined;
      this.startDate = data.startDate ?? undefined;
      this.endDate = data.endDate ?? undefined;
      this.location = data.location ?? undefined;
      this.registrationBeginDate = data.registrationBeginDate ?? undefined;
      this.registrationEndDate = data.registrationEndDate ?? undefined;
      this.targetGroupNumbers = data.targetGroupNumbers ?? undefined;
      this.eventTypeNumber = data.eventTypeNumber ?? undefined;
      this.categoryNumber = data.categoryNumber ?? undefined;
      this.groupingNumber = data.groupingNumber ?? undefined;
      this.availabilityStatus = data.availabilityStatus;
      this.onlinePaymentEnabled = data.onlinePaymentEnabled ?? undefined;
      this.websiteUrl = data.websiteUrl ?? undefined;
      this.eventIsPublished = data.eventIsPublished ?? undefined;
      this.targetGroupAsText = data.targetGroupAsText ?? undefined;
      this.minimumNumberOfParticipants = data.minimumNumberOfParticipants ?? undefined;
      this.maximumNumberOfParticipants = data.maximumNumberOfParticipants ?? undefined;
      this.totalPrices = data.totalPrices?.map((i) => EventPriceSummaryModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int32}
  */
  eventNumber?: number;
  /**
  * @type {int32}
  */
  businessUnitNumber?: number;
  /**
  */
  eventName?: string;
  /**
  */
  description?: string;
  /**
  * @type {date-time}
  */
  startDate?: string;
  /**
  * @type {date-time}
  */
  endDate?: string;
  /**
  */
  location?: string;
  /**
  * @type {date-time}
  */
  registrationBeginDate?: string;
  /**
  * @type {date-time}
  */
  registrationEndDate?: string;
  /**
  */
  targetGroupNumbers?: number[];
  /**
  * @type {int32}
  */
  eventTypeNumber?: number;
  /**
  * @type {int32}
  */
  categoryNumber?: number;
  /**
  * @type {int32}
  */
  groupingNumber?: number;
  /**
  * @type {int32}
  */
  availabilityStatus?: 1 | 2 | 3 | 4 | 5 | 6; // EventAvailabilityStatusDTO
  /**
  */
  onlinePaymentEnabled?: boolean;
  /**
  */
  websiteUrl?: string;
  /**
  */
  eventIsPublished?: boolean;
  /**
  */
  targetGroupAsText?: string;
  /**
  * @type {int32}
  */
  minimumNumberOfParticipants?: number;
  /**
  * @type {int32}
  */
  maximumNumberOfParticipants?: number;
  /**
  */
  totalPrices?: EventPriceSummaryModel[];

  static toModel(dto: DeepPartial<EventDTO>): EventModel;
  static toModel(dto: DeepPartial<EventDTO> | undefined | null): EventModel | undefined;
  static toModel(dto: DeepPartial<EventDTO> | undefined | null): EventModel | undefined {
    return dto ? new EventModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<EventModel> | EventModel): EventDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      eventNumber: unwrapped.eventNumber,
      businessUnitNumber: unwrapped.businessUnitNumber,
      eventName: unwrapped.eventName,
      description: unwrapped.description,
      startDate: unwrapped.startDate,
      endDate: unwrapped.endDate,
      location: unwrapped.location,
      registrationBeginDate: unwrapped.registrationBeginDate,
      registrationEndDate: unwrapped.registrationEndDate,
      targetGroupNumbers: unwrapped.targetGroupNumbers,
      eventTypeNumber: unwrapped.eventTypeNumber,
      categoryNumber: unwrapped.categoryNumber,
      groupingNumber: unwrapped.groupingNumber,
      availabilityStatus: unwrapped.availabilityStatus,
      onlinePaymentEnabled: unwrapped.onlinePaymentEnabled,
      websiteUrl: unwrapped.websiteUrl,
      eventIsPublished: unwrapped.eventIsPublished,
      targetGroupAsText: unwrapped.targetGroupAsText,
      minimumNumberOfParticipants: unwrapped.minimumNumberOfParticipants,
      maximumNumberOfParticipants: unwrapped.maximumNumberOfParticipants,
      totalPrices: unwrapped.totalPrices,
    } as EventDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for EventModel, can not map to EventDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
