import EventModel from '@/src/services/v2/model/event-model';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

export default {
  type: 'object',
  ...queryBuilder.if({ customerGroupNumber: EventModel.CUSTOMER_GROUP_NUMBER_MEMBER }, true),
  then: {
    properties: {
      eventParticipant: {
        ...queryBuilder.properties({
          customerNumber: { type: 'number', required: true },
        }),
      },
    },
  },
  properties: {
    message: { type: 'string', maxLength: 2000 },
    becameAwareBy: { type: 'string', maxLength: 250 },
    eventParticipant: {
      type: 'object',
      properties: {
        address: {
          ...queryBuilder.properties({
            companyName: { type: 'string' },
            salutation: { type: 'number', required: true },
            firstName: { type: 'string', required: true },
            lastName: { type: 'string', required: true },
            street: { type: 'string', required: true },
            houseNumber: { type: 'string' },
            postalCode: { type: 'string', required: true },
            city: { type: 'string', required: true },
            email: { type: 'string', required: true, pattern: validationPatterns.email },
            poBox: { type: 'string' },
            addressLine1: { type: 'string' },
            countryIso2: { type: 'string', required: true },
            phoneNumber: { type: 'string', pattern: validationPatterns.phone },
            mobilePhoneNumber: { type: 'string', required: true, pattern: validationPatterns.phone },
          }),
        },
      },
    },
  },
} as IJSONSchema;
