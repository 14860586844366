// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/online-payment-schema',
  type: 'object',
  required: ['amount'],
  properties: {
    transactionId: {
      type: 'string',
      nullable: true,
    },
    paymentDate: {
      type: 'string',
      format: 'date-time',
    },
    currency: {
      type: 'string',
      nullable: true,
    },
    amount: {
      type: 'number',
    },
  },
} as IJSONSchema;

export default schema;
