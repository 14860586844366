import { render, staticRenderFns } from "./kfmv-wizard-step.vue?vue&type=template&id=2d768a29&scoped=true"
import script from "./kfmv-wizard-step.vue?vue&type=script&lang=ts"
export * from "./kfmv-wizard-step.vue?vue&type=script&lang=ts"
import style0 from "./kfmv-wizard-step.vue?vue&type=style&index=0&id=2d768a29&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d768a29",
  null
  
)

export default component.exports