import Vue from 'vue';
import core, { CoreOptions } from '@glittr/frontend-core';
import router from './router';
import services from './services/v2';
import ilLanguageSelection from './components/il-language-selection.vue';
import kfmvWizard from './components/wizard/kfmv-wizard.vue';
import kfmvWizardStep from './components/wizard/kfmv-wizard-step.vue';
import kfmvCountryLookup from './components/lookup/kfmv-country-lookup.vue';
import kfmvWalleeWidget from './components/kfmv-wallee-widget.vue';

import kfmvEventRegistrationWidget from './views/widgets/event-registration/event-registration.vue';

Vue.config.productionTip = false;
// Core entry point
Vue.use(core, {
  // appId: 'custom-mount-id'
  router,
  Vue,
} as CoreOptions);

// APP____________________________________________________________

// STYLES
require('./assets/styles/main.scss');

// PLUGINS
Vue.use(services);

// COMPONENTS
Vue.component('IlLanguageSelection', ilLanguageSelection);
Vue.component('KfmvWizard', kfmvWizard);
Vue.component('KfmvWizardStep', kfmvWizardStep);
Vue.component('KfmvCountryLookup', kfmvCountryLookup);

Vue.component('KfmvEventRegistrationWidget', kfmvEventRegistrationWidget);
Vue.component('KfmvWalleeWidget', kfmvWalleeWidget);
// GUARDS
// Vue.$guards.push(yourGuard);

// LAYOUTS
const reqLayouts = require.context('./views/layouts', true);
reqLayouts.keys().forEach((componentPath) => {
  let componentName = componentPath.substr(componentPath.lastIndexOf('/') + 1, componentPath.length - 1);
  componentName = componentName.replace('.vue', '');
  const component = reqLayouts(componentPath).default;
  Vue.component(componentName, component);
});

// _______________________________________________________________

require('./store');

declare module '@core/plugins/config/config' {
  interface ConfigType {

    /** @deprecated This should not be needed, it has to be styled externally */
    'widget-event-arrowsrc': string,

    'widget-defaultcurrency': string,
    'widget-termsandconditionsurl': string,
    'widget-event-eventdetailurl': string,
    'widget-event-registrationsuccessurl': string,
    'widget-event-eventid': string,
    'widget-event-registrationurl': string,
    'widget-event-registrationtitle': string,
    'widget-event-partyroleid': string,
    'widget-event-ordertemplateid': string,
    'widget-event-businessunitnumber': number,
    'widget-event-customergroupnumber': number,
    /** The fixed customerGroupNumber signifying a member */
    'widget-event-customergroupnumbermember': number,
    /** The fixed customerGroupNumber signifying a non-member */
    'widget-event-customergroupnumbernonmember': number,
    'widget-event-addressownernumber':number
  }
}
