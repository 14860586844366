<template>
  <div v-if="!isLoading && eventInformation" class="widgeteventdetails " :class="isBookable(eventInformation.availabilityStatus) ? 'bookable' : 'soldout'">
    <div v-if="eventInformation" class="titlewrapper">
      <h1>{{ eventInformation.eventName }}</h1>
    </div>
    <div v-if="isBookable(eventInformation.availabilityStatus)" class="pricewrapper">
      <div v-if="getPrice(true) !== undefined" class="member" :class="{ free: (getPrice(true) === 0) }">
        <div class="currency">
          {{ getPriceCurrency(true) }}
        </div>
        <div class="price">
          {{ getPriceText(true) }}
        </div>
      </div>
      <div v-if="getPrice(false) !== undefined" class="nomember" :class="{ free: (getPrice(false) === 0) }">
        <div class="currency">
          {{ getPriceCurrency(false) }}
        </div>
        <div class="price">
          {{ getPriceText(false) }}
        </div>
      </div>
      <div v-if="getPrice(undefined) !== undefined" class="all" :class="{ free: (getPrice(undefined) === 0) }">
        <div class="currency">
          {{ getPriceCurrency(undefined) }}
        </div>
        <div class="price">
          {{ getPriceText(undefined) }}
        </div>
      </div>
    </div>
    <div class="statuswrapper">
      <span class="status">{{ getAvailabilityStatusText(eventInformation.availabilityStatus) }}</span>
    </div>
    <div v-if="isBookable(eventInformation.availabilityStatus)" class="registrationwrapper">
      <a class="registrationlink" :href="innerEventRegistrationUrl()">Anmelden</a>
    </div>
  </div>
  <p-progress-linear v-else-if="isLoading" indeterminate></p-progress-linear>
</template>
<script lang="ts">
  import EventModel from '@/src/services/v2/model/event-model';
  import GetKFMVEventStateInformationRequestModel from '@/src/services/v2/model/get-kfmv-event-state-information-request-model';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'WizardEventRegistration',
    layout: 'layout-widget',
    props: {
      eventId: { default: undefined, type: String },
      eventRegistrationUrl: { default: undefined, type: String },
      arrowsrc: { default: undefined, type: String },
      eventPartyRoleId: { default: undefined, type: String },
      language: { default: undefined, type: String },
    },
    data: () => ({
      isLoading: true,
      title: Vue.$t('kfmv.widget.event-detail.title'),
      eventInformation: undefined as EventModel | undefined,
    }),
    async mounted() {
      this.isLoading = true;
      if (this.innerEventId() && this.innerLanguage() && this.innerEventPartyRoleId()) {
        const eventInfoResponse = await this.$service.v2.api.events.getKFMVEventStateInformation(new GetKFMVEventStateInformationRequestModel({
          id: this.innerEventId(),
          language: this.innerLanguage(),
          eventPartyRoleId: this.innerEventPartyRoleId(),
        }));
        if (!eventInfoResponse || !eventInfoResponse.data) {
          this.$log.error(`Event with id '${this.innerEventId()}' not found!`);
        } else {
          this.eventInformation = eventInfoResponse.data;
        }
      } else if (!this.innerEventPartyRoleId()) {
        this.$log.error('Event id not set!');
      } else if (!this.innerEventId()) {
        this.$log.error('EventPartyRoleIdnot set!');
      } else if (!this.innerLanguage()) {
        this.$log.error('Language not set!');
      }
      this.isLoading = false;
    },
    methods: {
      getAvailabilityStatusText(stateNumber: Number | undefined): string | undefined {
        switch (stateNumber) {
        case 1: return 'Planned';
        case 2: return 'Available';
        case 3: return 'WaitingList';
        case 4: return 'FullyBooked';
        case 5: return 'Closed';
        case 6: return 'Cancelled';
        default: return 'Unknown';
        }
      },
      isBookable(stateNumber: Number | undefined): boolean {
        return stateNumber === 2 || stateNumber === 3;
      },
      innerEventId(): string | undefined {
        if (this._.isSet(this.eventId)) {
          return this.eventId;
        }
        const queries = this.$routerUtils.getQueryParams();
        return queries.eventId ?? this.getFromAttributeOrQuery('widget-event-eventid', 'eventId');
      },
      innerEventPartyRoleId(): string | undefined {
        if (this._.isSet(this.eventPartyRoleId)) {
          return this.eventPartyRoleId;
        }
        const queries = this.$routerUtils.getQueryParams();
        return queries.eventPartyRoleId ?? this.getFromAttributeOrQuery('widget-event-partyroleid', 'eventpartyrole');
      },
      innerLanguage(): string | undefined {
        return this.getFromAttributeOrQuery('language', 'language') ?? 'de';
      },
      innerEventRegistrationUrl(): string {
        if (this._.isSet(this.eventRegistrationUrl)) {
          return this.eventRegistrationUrl;
        }
        if (this.$config.values['widget-event-registrationurl']) {
          return this.$config.values['widget-event-registrationurl'];
        }
        const queries = this.$routerUtils.getQueryParams();
        return queries.eventRegistrationUrl ?? this.getFromAttributeOrQuery('widget-event-registrationurl', 'eventRegistrationUrl');
      },
      innerArrowsrc(): string {
        if (this._.isSet(this.arrowsrc)) {
          return this.arrowsrc;
        }
        const queries = this.$routerUtils.getQueryParams();
        return queries.arrowsrc ?? this.getFromAttributeOrQuery('widget-event-arrowsrc', 'arrowsrc');
      },
      getPrice(isMembership:boolean | undefined):number | undefined {
        const memberPrice = this.eventInformation?.memberPriceItem?.price;
        const nonMemberPrice = this.eventInformation?.nonMemberPriceItem?.price;
        const pricesAreEqual = memberPrice === nonMemberPrice;
        if ((isMembership === undefined && !pricesAreEqual) || (isMembership !== undefined && pricesAreEqual)) {
          return undefined;
        }
        if (pricesAreEqual) {
          return nonMemberPrice;
        }
        return isMembership ? memberPrice : nonMemberPrice;
      },
      getPriceText(isMembership: boolean | undefined):number | undefined {
        const price = this.getPrice(isMembership);
        if (price === undefined) return undefined;
        return price;
      },
      getPriceCurrency(isMembership: boolean | undefined):string | undefined {
        return Vue.$config.values['widget-defaultcurrency'];
      },
      getFromAttributeOrQuery<T>(configKey:string, querykey: string): T | undefined {
        configKey = configKey.toLowerCase();
        querykey = querykey.toLowerCase();
        const queries = this.$routerUtils.getQueryParams();
        const queriesLowerCase = {} as any;
        Object.keys(queries).forEach((queryKeyName) => {
          queriesLowerCase[queryKeyName.toLowerCase()] = queries[queryKeyName];
        });
        const value = (this.$config.values as any)[configKey] ?? this.$router.currentRoute.params[querykey] ?? queriesLowerCase[querykey];
        return value;
      },
    },
  });
  </script>
