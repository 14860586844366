<template>
  <p-container class="widget event detail form fill-height">
    <p-row column no-gutters>
      <p-col shrink>
        <h2>{{ $t('page.event-detail-configuration.title') }}</h2>
      </p-col>
      <p-col shrink>
        <p-card>
          <p-row>
            <p-col xs12 sm6 md4 lg2>
              <p-select
                v-model="widgetConfig.language"
                :label="$t('page.settings.title.languageSettings')"
                :items="languages"
              ></p-select>
            </p-col>
            <p-col xs12 sm6 md4 lg4 xl2>
              <p-text-field
                v-model="widgetConfig['widget-event-eventid']"
                required
                :label="$t('page.event-detail-configuration.eventId.label')"
              ></p-text-field>
            </p-col>
            <p-col xs12 sm6 md4 lg2 xl1>
              <p-text-field
                v-model="widgetConfig['widget-event-arrowsrc']"
                :label="$t('page.event-detail-configuration.arrowsrc.label')"
              ></p-text-field>
            </p-col>
            <p-col xs12 sm6 md6 lg4 xl2>
              <p-text-field
                v-model="widgetConfig['widget-event-registrationurl']"
                required
                :label="$t('page.event-detail-configuration.registrationUrl.label')"
              ></p-text-field>
            </p-col>
            <p-col xs12 sm6 md4 lg4 xl3>
              <p-text-field
                v-model="widgetConfig['widget-event-partyroleid']"
                required
                :label="$t('page.event-detail-configuration.eventPartyRole.label')"
              ></p-text-field>
            </p-col>

            <p-col sm6 md2 :xs12="!$media.isDesktop" :justify-end="!$media.isDesktop" align-end>
              <p-button
                :disabled="widgetIsLoading || !(widgetConfig['widget-event-eventid'] && widgetConfig['widget-event-registrationurl'] && widgetConfig['widget-event-partyroleid'] && widgetConfig.language)"
                @click="openWidget(widgetConfig['widget-event-eventid'], widgetConfig['widget-event-registrationurl'], widgetConfig['widget-event-arrowsrc'], widgetConfig.language, widgetConfig['widget-event-partyroleid'])"
              >
                {{ $t('core.app.ok') }}
              </p-button>
              <p-button :title="$t('widget.config-pages.embed-info.hint')" class="ml-2" secondary @click="toggleWidgetEmbedInfo()">
                <p-icon :color="isWidgetEmbedInfoVisible ? 'base' : undefined">
                  dataset_linked
                </p-icon>
                <p-icon right>
                  {{ isWidgetEmbedInfoVisible ? 'expand_less' : 'expand_more' }}
                </p-icon>
              </p-button>
            </p-col>
          </p-row>
        </p-card>
      </p-col>
      <p-card v-if="isWidgetEmbedInfoVisible" class="ma-0">
        <div v-if="!link">
          {{ $t('widget.config-pages.embed-info.no-link-message') }}
        </div>
        <template v-else>
          <p-col shrink>
            <p-row align-center nowrap>
              <p-col shrink>
                <a ref="link" :href="link" target="_blank" rel="noopener noreferrer">{{ link }}</a>
              </p-col>
              <p-col grow>
                <p-row dense>
                  <p-button secondary @click="_.copyToClipboard($refs.link)">
                    <p-icon>content_copy</p-icon>
                  </p-button>
                </p-row>
              </p-col>
            </p-row>
          </p-col>
          <p-divider class="mb-4"></p-divider>
          <p-col>
            <p-row>
              <p-col grow>
                <pre ref="widgetEmbedCode" class="code">{{ widgetEmbedSnippet }}</pre>
              </p-col>
              <p-col shrink>
                <p-button secondary @click="_.copyToClipboard($refs.widgetEmbedCode)">
                  <p-icon>content_copy</p-icon>
                </p-button>
              </p-col>
            </p-row>
          </p-col>
        </template>
      </p-card>
      <template v-if="link">
        <p-col>
          <div id="result" class="fill-height fill-width pt-4">
            <!-- WIDGET RENDERED HERE -->
          </div>
        </p-col>
      </template>
    </p-row>
  </p-container>
</template>
<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    data: () => ({
      isWidgetEmbedInfoVisible: false,
      widgetConfig: {
        'mount-id': 'widget-event-detail-id',
        route: '_widgets/event/detail',
        language: 'de',
        'widget-event-eventid': undefined,
        'widget-event-registrationurl': undefined,
        'widget-event-arrowsrc': undefined,
        'widget-event-partyroleid': undefined,
      } as Record<string, string | undefined>,
      widgetEmbedSnippet: undefined as string | undefined,
      link: undefined as string | undefined,
      widgetHost: `${window.location.protocol}//${window.location.host}`,
      widgetIsLoading: false,
    }),
    computed: {
      languages() {
        return this.$translation.availableAppLanguages.map((lang) => ({
          id: lang.toLowerCase(),
          caption: this.$t(`app.language.${lang.toLowerCase()}`),
          description: lang.toLowerCase(),
        }));
      },
    },
    mounted() {
      this.widgetConfig.language = this.$config.getQueryOrValue('language') ?? this.widgetConfig.language;
      this.widgetConfig['widget-event-eventid'] = this.$config.getQueryOrValue('widget-event-eventid', 'eventId') ?? this.widgetConfig['widget-event-eventid']?.toString();
      this.widgetConfig['widget-event-registrationurl'] = this.$config.getQueryOrValue('widget-event-registrationurl', 'registrationurl') ?? this.widgetConfig['widget-event-registrationurl']?.toString();
      this.widgetConfig['widget-event-arrowsrc'] = this.$config.getQueryOrValue('widget-event-arrowsrc', 'arrowsrc') ?? this.widgetConfig['widget-event-arrowsrc']?.toString();
      this.widgetConfig['widget-event-partyroleid'] = this.$config.getQueryOrValue('widget-event-partyroleid', 'eventPartyRole') ?? this.widgetConfig['widget-event-partyroleid']?.toString();
    },
    methods: {
      toggleWidgetEmbedInfo() {
        this.isWidgetEmbedInfoVisible = !this.isWidgetEmbedInfoVisible;
      },
      createWidgetEmbedSnippet(scriptQueries?: Record<string, string>) {
        const widgetConfig = this.widgetConfig as Record<string, any>;
        const widgetAttributes = Object.keys(widgetConfig)
          .filter((key) => widgetConfig[key] !== undefined)
          .map((key) => `data-${key}="${widgetConfig[key]}"`)
          .join('\n ');
        const queries = scriptQueries ? `?${Object.keys(scriptQueries).map((i) => `${i}=${scriptQueries[i]}`).join('&')}` : '';
        return `
          <link href="${this.widgetHost}/css/app.css" rel="stylesheet" />
          <div id="${widgetConfig['mount-id']}"></div>
            <script
              async="async"
              data-router-mode="abstract"
              data-config-path="${this.widgetHost}"
              data-auth-apikey="GL-*****************************"
              src="${this.widgetHost}/js/app.js${queries}"
              ${widgetAttributes}
            ><\/script>`
          .replace(/[ \t]+/g, ' ')
          .replace(/^[ \t]/gm, '')
          .trim();
      },
      async openWidget(eventId:string | undefined, eventRegistrationUrl:string | undefined, arrowsrc:string | undefined, language:string | undefined, eventPartyRole:string | undefined) {
        if (this.widgetIsLoading) return;
        try {
          this.widgetIsLoading = true;
          const queries = {
            eventId, eventRegistrationUrl, arrowsrc, language, eventPartyRole,
          } as Record<string, string>;
          const queryString = Object.keys(queries)
            .filter((key) => queries[key] !== undefined && queries[key] !== null && queries[key] !== '')
            .map((key) => `${key}=${encodeURIComponent(queries[key])}`)
            .join('&');
          this.link = new URL(`${this.widgetHost}/${this.widgetConfig.route}?${queryString}`, document.baseURI).href;
          this.widgetEmbedSnippet = this.createWidgetEmbedSnippet({ cacheBust: Date.now().toString() });
          this.$nextTick(() => {
            const resultElement = document.getElementById('result') as HTMLElement;
            resultElement.replaceChildren(document.createRange().createContextualFragment(this.widgetEmbedSnippet!));
            setTimeout(() => { this.widgetIsLoading = false; }, 1000);
          });
        } catch (error) {
          this.widgetIsLoading = false;
        }
      },
    },
  });
  </script>
