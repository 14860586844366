<template>
  <kfmv-wizard
    v-if="eventInformation"
    ref="wizard"
    class="widget-event-registration"
    :title="getWizardTitle()"
    :storagekey="`event-registration-${eventInformation.id}`"
    :steps="steps"
    :initialize-data="initializeData"
    :service-function="serviceFunction"
    :model-class="model"
    to-on-complete="/"
  ></kfmv-wizard>
</template>

<script lang="ts">
  import Vue from 'vue';

  import { StepData } from '@/src/components/wizard/kfmv-wizard.vue';

  import EventModel from '@/src/services/v2/model/event-model';
  import PaymentMethodDTO from '@/src/services/v2/_generated/dto/payment-method-dto';
  import ContactModel from '@/src/services/v2/model/contact-model';
  import ContactAddressModel from '@/src/services/v2/model/contact-address-model';
  import GetKFMVEventStateInformationRequestModel from '@/src/services/v2/model/get-kfmv-event-state-information-request-model';
  import CreateEventRegistrationRequestModel from '@/src/services/v2/model/create-event-registration-request-model';
  import paymentSchema from './4.payment.event-registration.schema';
  import priceSelectionOptionsSchema from './2.priceSelectionOptions.event-registration.schema';
  import priceSelectionSchema from './1.priceSelection.event-registration.schema';

  export default Vue.extend({
    name: 'WizardEventRegistration',
    layout: 'layout-widget',
    props: {
      language: { default: 'de', type: String },
      bussinesUnit: { default: undefined, type: Number },
      eventId: { default: undefined, type: String },
      eventPartyRoleId: { default: undefined, type: String },
      customerGroupNumber: { default: undefined, type: Number },
      eventOrderTemplateId: { default: undefined, type: String },
      title: { default: '', type: String },
      addressOwner: { default: undefined, type: Number },
    },
    data: () => ({
      steps: [] as StepData[],
      model: CreateEventRegistrationRequestModel,
      eventInformation: undefined as EventModel | undefined,
      innerEventId: undefined as string | undefined,
      innerCustomerGroupNumber: undefined as number | undefined,
      innerBusinessUnitNumber: undefined as number | undefined,
      innerEventPartyRoleId: undefined as string | undefined,
      innerOrderTemplateId: undefined as string | undefined,
      innerLanguage: undefined as string | undefined,
      innerAddressOwner: undefined as number | undefined,
    }),
    computed: {
      defaultPaymentMethod() {
        return (6) as PaymentMethodDTO;
      },
    },
    async mounted() {
      this.steps = [
        {
          breadcrumb: 'kfmv.widget.eventregistration.priceSelection_title',
          component: () => import('./1.priceSelection.event-registration.vue'),
          schema: priceSelectionSchema,
          disablePreviousSteps: true,
        },
        {
          breadcrumb: 'kfmv.widget.eventregistration.priceSelectionOptions_title',
          component: () => import('./2.priceSelectionOptions.event-registration.vue'),
          schema: priceSelectionOptionsSchema,
          condition: (wizardData: CreateEventRegistrationRequestModel) => false, // This step is not needed anymore
        },
        {
          breadcrumb: 'kfmv.widget.eventregistration.contactData_title',
          component: () => import('./3.contactData.event-registration.vue'),
          // Schema needs to be imported on mount because it uses config values
          // Config values are only available after the app has loaded
          schema: (await import('./3.contactData.event-registration.schema')).default,
        },
        {
          breadcrumb: 'kfmv.widget.eventregistration.payment_title',
          component: () => import('./4.payment.event-registration.vue'),
          schema: paymentSchema,
          disablePreviousSteps: (wizardData: CreateEventRegistrationRequestModel) => !!wizardData.basket?.onlinePayment?.transactionId,
        },
      ];

      this.innerEventId = this.$config.getQueryOrValue('widget-event-eventid', 'eventId') ?? this.eventId;
      const customerGroupNumber = this.$config.getQueryOrValue('widget-event-customergroupnumber', 'customerGroupNumber') ?? this.customerGroupNumber?.toString();
      this.innerCustomerGroupNumber = customerGroupNumber !== undefined ? Number.parseInt(customerGroupNumber, 10) : undefined;
      const businessUnitNumber = this.$config.getQueryOrValue('widget-event-businessunitnumber', 'businessUnit') ?? this.bussinesUnit?.toString();
      this.innerBusinessUnitNumber = businessUnitNumber !== undefined ? Number.parseInt(businessUnitNumber, 10) : undefined;
      this.innerEventPartyRoleId = this.$config.getQueryOrValue('widget-event-partyroleid', 'eventPartyRole') ?? this.eventPartyRoleId;
      this.innerOrderTemplateId = this.$config.getQueryOrValue('widget-event-ordertemplateid', 'eventOrderTemplate') ?? this.eventOrderTemplateId;
      this.innerLanguage = this.$config.getQueryOrValue('language', 'language') ?? this.language;
      this.innerAddressOwner = +(this.$config.getQueryOrValue('widget-event-addressownernumber', 'addressownernumber') ?? this.addressOwner);
      if (this.innerEventId === undefined) { throw Error('Event id not set!'); }
      if (!this.innerAddressOwner || Number.isNaN(this.innerAddressOwner)) { throw Error('AddressOwnerNumber not set!'); }
      if (!this.innerBusinessUnitNumber || Number.isNaN(this.innerBusinessUnitNumber)) { throw Error('BusinessUnitNumber not set!'); }
      if (this.innerEventPartyRoleId === undefined) { throw Error('EventPartyRoleId not set!'); }

      const eventInfoResponse = await Vue.$service.v2.api.events.getKFMVEventStateInformation(new GetKFMVEventStateInformationRequestModel({
        id: this.innerEventId,
        language: this.innerLanguage,
        eventPartyRoleId: this.innerEventPartyRoleId,
      }));
      this.eventInformation = eventInfoResponse.data;
    },
    methods: {
      getWizardTitle(): string {
        const eventInforationTmp = this.eventInformation ?? new EventModel();

        if (this._.isSet(this.title)) {
          return this.title.replaceAll('{0}', eventInforationTmp.eventName!);
        }
        if (this.$config.values['widget-event-registrationtitle']) {
          return this.$config.values['widget-event-registrationtitle'].replaceAll('{0}', eventInforationTmp.eventName!);
        }
        const queries = this.$routerUtils.getQueryParams();
        if (queries.title) {
          return queries.title.replaceAll('{0}', eventInforationTmp.eventName!);
        }
        return Vue.$t('kfmv.widget.eventregistrationwizard.wizard_title').replaceAll('{0}', eventInforationTmp.eventName!);
      },
      async serviceFunction(wizardData: CreateEventRegistrationRequestModel) {
        // Custom complete function (see last step), this one is not used
      },
      async initializeData() {
        const initializedModel = new CreateEventRegistrationRequestModel({
          eventPartyRoleId: this.innerEventPartyRoleId,
          eventOrderTemplate: this.innerOrderTemplateId,
          customerGroupNumber: this.innerCustomerGroupNumber,
          languageIso2: this.innerLanguage,
          businessUnit: this.innerBusinessUnitNumber,
          eventId: this.innerEventId,
          contactAddressOwnerNumber: this.innerAddressOwner,
          eventParticipant: new ContactModel({
            address: new ContactAddressModel(),
          }),
          basket: {
            items: [],
            paymentMethod: this.defaultPaymentMethod,
            currency: this.$config.values['widget-defaultcurrency'],
            grossTotal: undefined,
          },
        });

        return initializedModel;
      },
    },
  });
</script>
