<template>
  <kfmv-wizard-step class="wizard-step-create-event-registration" :custom-proceed-function="customProceed" :errors="validationError">
    <p-request-alert v-model="error"></p-request-alert>
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <div class="eventregistration-price-selection-options" :class="classList">
      <p-row v-if="eventInfo && eventInfo.options" column>
        <p-checkbox
          v-for="item in eventInfo.options.filter(x => x.items && x.items.length === 1)"
          :key="item.id"
          :label="getItemLable(item, item.items[0].id)"
        >
        </p-checkbox>
        <p-fieldset v-for="item in eventInfo.options.filter(x => x.items && x.items.length > 1)" :key="item.id" :label="item.title">
          <p-radio-field
            v-for="itemOption in item.items"
            :key="itemOption.id"
            :title="$t(`kfmv.widget.eventregistration.priceselectionOptions.${itemOption.membership ? 'memberprice' : 'notmemberprice'}`)"
            :true-value="itemOption.membership"
            :value="wizardData.membership"
            :label="getItemLable(item, itemOption.id)"
          >
          </p-radio-field>
        </p-fieldset>
      </p-row>
    </div>
  </kfmv-wizard-step>
</template>

<script lang="ts">
  import { PropType } from 'vue';
  import mixins from '@glittr/frontend-core/src/mixins';
  import validatable from '@glittr/frontend-core/src/mixins/validatable/validatable';
  import CreateEventRegistrationRequestModel from '@/src/services/v2/model/create-event-registration-request-model';
  import EventModel from '@/src/services/v2/model/event-model';
  // import EventOptionsModel from '@/src/services/v2/model/event-options-model';

  export default mixins(validatable).extend({
    name: 'WizardStepEventRegistrationPriceSelectionOptions',
    props: {
      value: { type: Object, default: undefined },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      clearProgressFunction: { type: Function as PropType<(()=>Promise<void>)>, default: () => {} },
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      validationError: undefined as string | undefined,
      eventInfo: undefined as EventModel | undefined,
      selection: [] as Array<number>,
    }),

    computed: {
      classList(): Record<string, boolean> {
        return {
          'input-error': this._.isSet(this.validationError),
        };
      },
      wizardData: {
        get(): CreateEventRegistrationRequestModel {
          return this.value ?? {};
        },
        set(value: CreateEventRegistrationRequestModel) {
          this.$emit('input', value);
        },
      },
    },
    async beforeMount() {
      // // clear WidgetAnalyticsData
      // const WidgetWindow = window as any;
      // WidgetWindow.glitWidget = {};
      // this.$sessionStorage.remove('widget-analytics-window');

      // this.isLoading = true;
      // try {
      //   const eventInfoResponse = await this.$service.v2.api.event.eventInformation(new EventInformationRequestModel({ eventId: this.wizardData.eventId }));
      //   this.eventInfo = eventInfoResponse.data;
      //   if (this._.isSet(this.wizardData.options)) {
      //     this.selection = this.wizardData.options.map((x:EventOptionsModel) => x.id!);
      //   }
      // } catch (error: any) {
      //   this.error = error;
      //   this.$log.error(error);
      // }
      // /* if (this._.isSet(this.wizardData.chosenMembershipTemplate)) {
      //   this.wizardData.chosenMembershipTemplate.hasPartnerAddress = false;
      // } */
      // this.isLoading = false;
    },
    methods: {
      // validate(): boolean {
      //   // if (!this.eventInfo?.prices?.some((a) => a.membership === this.wizardData.membership)) {
      //   //   this.validationError = this.$t('kfmv.widget.eventregistration.priceselectionOptions_error');
      //   //   return false;
      //   // }
      //   return true;
      // },
      // customProceed() {
      //   if (this.validate()) {
      //     // this.wizardData.options = this.eventInfo?.options?.filter((x:EventOptionsModel) => this.selection.some((y) => y === x.id!));
      //     this.proceedFunction!();
      //   }
      // },
      // async onEventRegistrationSelect(id: any, membership: any) {
      //   await this.clearProgressFunction!();
      //   // this.wizardData.membershipTemplateId = membership.id!;
      //   // this.wizardData.isGiftMembership = false;
      //   // this.$set(this.wizardData, 'chosenMembershipTemplate', membership);
      // },
      // getItemLable(item:EventOptionsModel, id:number | undefined):string {
      //   const subItem = item!.items!.filter((x) => x.id === id)[0];
      //   return `${item.title} ${subItem.currency} ${subItem.price}`;
      // },

    },
  });
</script>
