import { render, staticRenderFns } from "./kfmv-wallee-widget.vue?vue&type=template&id=72b6a768"
import script from "./kfmv-wallee-widget.vue?vue&type=script&lang=ts"
export * from "./kfmv-wallee-widget.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports