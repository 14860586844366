// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import EventPriceSummarySchema from './event-price-summary-schema';

const schema = {
  $id: '/event-schema',
  type: 'object',
  required: ['eventNumber', 'businessUnitNumber'],
  properties: {
    id: {
      type: 'string',
      format: 'uuid',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    eventNumber: {
      type: 'integer',
    },
    businessUnitNumber: {
      type: 'integer',
    },
    eventName: {
      type: 'string',
      nullable: true,
    },
    description: {
      type: 'string',
      nullable: true,
    },
    startDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    endDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    location: {
      type: 'string',
      nullable: true,
    },
    registrationBeginDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    registrationEndDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    targetGroupNumbers: {
      type: 'array',
      nullable: true,
      items: {
        type: 'integer',
      },
    },
    eventTypeNumber: {
      type: 'integer',
      nullable: true,
    },
    categoryNumber: {
      type: 'integer',
      nullable: true,
    },
    groupingNumber: {
      type: 'integer',
      nullable: true,
    },
    availabilityStatus: {
      enum: [
        1,
        2,
        3,
        4,
        5,
        6,
      ],
    },
    onlinePaymentEnabled: {
      type: 'boolean',
      nullable: true,
    },
    websiteUrl: {
      type: 'string',
      nullable: true,
    },
    eventIsPublished: {
      type: 'boolean',
      nullable: true,
    },
    targetGroupAsText: {
      type: 'string',
      nullable: true,
    },
    minimumNumberOfParticipants: {
      type: 'integer',
      nullable: true,
    },
    maximumNumberOfParticipants: {
      type: 'integer',
      nullable: true,
    },
    totalPrices: {
      type: 'array',
      nullable: true,
      items: { ...EventPriceSummarySchema },
    },
  },
} as IJSONSchema;

export default schema;
