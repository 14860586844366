// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetEventsListRequestModel from '../../model/get-events-list-request-model';
import GetKFMVEventStateInformationRequestModel from '../../model/get-kfmv-event-state-information-request-model';
import CreateEventCheckoutRequestModel from '../../model/create-event-checkout-request-model';
import GetEventCheckoutByIdRequestModel from '../../model/get-event-checkout-by-id-request-model';
import UpdateEventCheckoutRequestModel from '../../model/update-event-checkout-request-model';
import CreateEventRegistrationRequestModel from '../../model/create-event-registration-request-model';
import EventListResultModel from '../../model/event-list-result-model';
import EventModel from '../../model/event-model';
import EventCheckoutModel from '../../model/event-checkout-model';

export default (service: Servicelayer) => ({
  /**  */
  async getEventsList(request: GetEventsListRequestModel, config?: RequestConfig) {
    const requestDTO = GetEventsListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-events-list'] ?? 'Widget/Events';
    const response = await service.get<any>(endpointPath, {
      query: {
        languageIso2: requestDTO.languageIso2,
        searchCriteria: requestDTO.searchCriteria,
        eventTargetAudienceId: requestDTO.eventTargetAudienceId,
        eventCategoryId: requestDTO.eventCategoryId,
        businessUnitNumber: requestDTO.businessUnitNumber,
        dateRangeFrom: requestDTO.dateRangeFrom,
        dateRangeTo: requestDTO.dateRangeTo,
        eventPlace: requestDTO.eventPlace,
        eventGroupingNumber: requestDTO.eventGroupingNumber,
        isEventPublished: requestDTO.isEventPublished,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(EventListResultModel.toModel(dto));
  },
  /**  */
  async getKFMVEventStateInformation(request: GetKFMVEventStateInformationRequestModel, config?: RequestConfig) {
    const requestDTO = GetKFMVEventStateInformationRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-kfmv-event-state-information'] ?? 'Widget/Events/{id}/kfmv-detail/{language}/{eventPartyRoleId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    endpointPath = endpointPath.replace(/\{language\}/g, `${requestDTO.language}`);
    endpointPath = endpointPath.replace(/\{eventPartyRoleId\}/g, `${requestDTO.eventPartyRoleId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(EventModel.toModel(dto));
  },
  /**  */
  async createEventCheckout(request: CreateEventCheckoutRequestModel, config?: RequestConfig) {
    const requestDTO = CreateEventCheckoutRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-event-checkout'] ?? 'Widget/Events/{id}/Checkout';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        eventId: requestDTO.eventId,
        languageIso2: requestDTO.languageIso2,
        businessUnitNumber: requestDTO.businessUnitNumber,
        eventPartyRoleId: requestDTO.eventPartyRoleId,
        customerGroupNumber: requestDTO.customerGroupNumber,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(EventCheckoutModel.toModel(dto));
  },
  /**  */
  async getEventCheckoutById(request: GetEventCheckoutByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetEventCheckoutByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-event-checkout-by-id'] ?? 'Widget/Events/{eventId}/Checkout/{id}';
    endpointPath = endpointPath.replace(/\{eventId\}/g, `${requestDTO.eventId}`);
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(EventCheckoutModel.toModel(dto));
  },
  /**  */
  async updateEventCheckout(request: UpdateEventCheckoutRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateEventCheckoutRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-event-checkout'] ?? 'Widget/Events/{eventId}/Checkout/{id}';
    endpointPath = endpointPath.replace(/\{eventId\}/g, `${requestDTO.eventId}`);
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        checkoutRequest: requestDTO.checkoutRequest,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(EventCheckoutModel.toModel(dto));
  },
  /**  */
  async createEventRegistration(request: CreateEventRegistrationRequestModel, config?: RequestConfig) {
    const requestDTO = CreateEventRegistrationRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-event-registration'] ?? 'Widget/Events/Register';
    const response = await service.post<any>(endpointPath, {
      body: {
        eventId: requestDTO.eventId,
        languageIso2: requestDTO.languageIso2,
        businessUnit: requestDTO.businessUnit,
        eventPartyRoleId: requestDTO.eventPartyRoleId,
        eventOrderTemplate: requestDTO.eventOrderTemplate,
        customerGroupNumber: requestDTO.customerGroupNumber,
        contactAddressOwnerNumber: requestDTO.contactAddressOwnerNumber,
        eventParticipant: requestDTO.eventParticipant,
        basket: requestDTO.basket,
        summary: requestDTO.summary,
        becameAwareBy: requestDTO.becameAwareBy,
        message: requestDTO.message,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
