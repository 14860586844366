// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/create-event-checkout-request-schema',
  type: 'object',
  required: ['eventId', 'languageIso2', 'businessUnitNumber', 'eventPartyRoleId', 'customerGroupNumber', 'id'],
  properties: {
    eventId: {
      type: 'string',
      format: 'uuid',
    },
    languageIso2: {
      type: 'string',
      minLength: 1,
    },
    businessUnitNumber: {
      type: 'integer',
    },
    eventPartyRoleId: {
      type: 'string',
      format: 'uuid',
    },
    customerGroupNumber: {
      type: 'integer',
    },
    id: {
      type: 'string',
    },
  },
} as IJSONSchema;

export default schema;
