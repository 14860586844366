// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetEventsListRequestModel from '../../model/get-events-list-request-model';
import GetEventsListRequestDTO from '../dto/get-events-list-request-dto';
import dtoSchema from '../schemas/get-events-list-request-schema';

export default abstract class GetEventsListRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetEventsListRequestDTO>) {
    super();
    if (data) {
      this.languageIso2 = data.languageIso2;
      this.searchCriteria = data.searchCriteria;
      this.eventTargetAudienceId = data.eventTargetAudienceId;
      this.eventCategoryId = data.eventCategoryId;
      this.businessUnitNumber = data.businessUnitNumber;
      this.dateRangeFrom = data.dateRangeFrom;
      this.dateRangeTo = data.dateRangeTo;
      this.eventPlace = data.eventPlace;
      this.eventGroupingNumber = data.eventGroupingNumber;
      this.isEventPublished = data.isEventPublished;
    }
  }
  /**
  */
  languageIso2?: string;
  /**
  */
  searchCriteria?: string;
  /**
  * @type {int32}
  */
  eventTargetAudienceId?: number;
  /**
  * @type {int32}
  */
  eventCategoryId?: number;
  /**
  * @type {int32}
  */
  businessUnitNumber?: number;
  /**
  * @type {date-time}
  */
  dateRangeFrom?: string;
  /**
  * @type {date-time}
  */
  dateRangeTo?: string;
  /**
  */
  eventPlace?: string;
  /**
  * @type {int32}
  */
  eventGroupingNumber?: number;
  /**
  */
  isEventPublished?: boolean;

  static toModel(dto: DeepPartial<GetEventsListRequestDTO>): GetEventsListRequestModel;
  static toModel(dto: DeepPartial<GetEventsListRequestDTO> | undefined | null): GetEventsListRequestModel | undefined;
  static toModel(dto: DeepPartial<GetEventsListRequestDTO> | undefined | null): GetEventsListRequestModel | undefined {
    return dto ? new GetEventsListRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetEventsListRequestModel> | GetEventsListRequestModel): GetEventsListRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      languageIso2: unwrapped.languageIso2,
      searchCriteria: unwrapped.searchCriteria,
      eventTargetAudienceId: unwrapped.eventTargetAudienceId,
      eventCategoryId: unwrapped.eventCategoryId,
      businessUnitNumber: unwrapped.businessUnitNumber,
      dateRangeFrom: unwrapped.dateRangeFrom,
      dateRangeTo: unwrapped.dateRangeTo,
      eventPlace: unwrapped.eventPlace,
      eventGroupingNumber: unwrapped.eventGroupingNumber,
      isEventPublished: unwrapped.isEventPublished,
    } as GetEventsListRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetEventsListRequestModel, can not map to GetEventsListRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
