<template>
  <p-app theme="glittr">
    <div slot="header" class="ml-4 mt-4">
      <p-img width="130" to="/_exterior/login" contain :src="require('@src/assets/logo.png')"></p-img>
    </div>
    <p-container slot="default" fill-height>
      <p-row justify-center>
        <p-col xs12 md10 lg8 xl6>
          <p-card>
            <slot class="content" />
          </p-card>
        </p-col>
      </p-row>
    </p-container>
  </p-app>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'LayoutExterior',
    data: () => ({
      theme: 'glittr-pixel-light',
    }),
  });
</script>
