<template>
  <kfmv-wizard-step class="wizard-step-create-event-registration">
    <p-request-alert v-model="error"></p-request-alert>
    <p-progress-linear v-if="isLoading || isPaymentWidgetVisible" class="my-5" indeterminate></p-progress-linear>
    <div v-if="wizardData.basket && !isPaymentWidgetVisible" class="eventregistration-payment">
      <p-row v-for="item in basketItems" :key="item.itemNumber" class="basket-item-entry">
        <p-col xs8 lg5 class="caption-col">
          <span class="caption">{{ $tAlt(item.caption, item.caption) }}</span>
        </p-col>
        <p-col xs4 lg4 class="price-col">
          <span class="price">{{ $format.currency(item.grossPrice, item.currency) }}</span>
          <p-icon v-if="wizardData.basket.promotionCode && item.promotionCode === wizardData.basket.promotionCode" title="" right color="app-3">
            card_giftcard
          </p-icon>
        </p-col>
      </p-row>
      <p-divider></p-divider>
      <p-row class="basket-total mt-1 ">
        <p-col xs8 lg5 class="caption-col">
          <span class="caption">{{ $t('kfmv.widget.eventregistration.summary.total') }}</span>
        </p-col>
        <p-col xs4 lg4 class="price-col">
          <span class="price">{{ $format.currency(wizardData.basket.grossTotal, wizardData.basket.currency) }}</span>
          <p-divider></p-divider>
        </p-col>
      </p-row>
      <p-row v-if="!hasZeroCosts" class="basket-promotion-code mt-4 ">
        <p-col xs8 lg5>
          <p-text-field
            v-model="promotionCode"
            solo
            :disabled="isPromotionCodeInUse"
            :label="$t('kfmv.widget.eventregistration.promocode.watermark')"
          >
            <template #actions>
              <p-icon v-if="isPromotionCodeInUse">
                card_giftcard
              </p-icon>
            </template>
          </p-text-field>
        </p-col>
        <p-col xs4 lg4 align-end>
          <p-button class="mr-1" :disabled="!isPromotionCodeInUse || isCheckoutLoading || isLoading" :secondary="!isPromotionCodeInUse" @click="clearPromotionCode()">
            <p-icon>clear</p-icon>
          </p-button>
          <p-button :disabled="isCheckoutLoading || isLoading || isPromotionCodeInUse || hasTransaction || isPaymentWidgetVisible" @click="usePromotionCode(promotionCode)">
            {{ $t('kfmv.widget.eventregistration.promocode.title') }}
          </p-button>
        </p-col>
      </p-row>
      <p-row dense class="mt-5 ">
        <p-col>
          <p-alert v-model="latestPromoCodeNotFound" type="error">
            {{ $t('kfmv.widget.eventregistration.invalidpromocode.message') }}
          </p-alert>
        </p-col>
      </p-row>
      <p-row dense class="mt-5 ">
      </p-row>
      <p-row dense class="mt-5 ">
        <p-col>
          <a :href="$config.values['widget-termsandconditionsurl']" target="_blank" rel="noopener noreferrer">{{ $t('kfmv.widget.eventregistration.termsAndConditionsLink') }}</a>
        </p-col>
      </p-row>
      <p-row dense>
        <p-col>
          <p-checkbox v-model="wizardData.hasAgreedToTermsAndConditions" required :errors="termsAndConditionsError" :label="$t('kfmv.widget.eventregistration.agreeToTermsAndConditions')" />
        </p-col>
      </p-row>
    </div>

    <div v-if="wizardData.basket && isPaymentWidgetVisible" class="eventregistration-payment">
      <kfmv-wallee-widget
        v-if="wizardData.basket.onlinePayment"
        class="wallee-widget"
        :show="isPaymentWidgetVisible"
        :initialize="isPaymentWidgetVisible"
        :transaction-id="wizardData.basket.onlinePayment.transactionId"
        :success-url="buildRedirectUrl('success')"
        :failed-url="buildRedirectUrl('failed')"
      ></kfmv-wallee-widget>
    </div>
    <div class="mt-4">
      <p-alert class="payment-error" :value="isPaymentErrorVisible" type="error">
        <span class="keep-line-breaks payment-error-message">{{ $t('kfmv.widget.eventregistration.paymentFailed') }}</span>
      </p-alert>
    </div>
    <template #step-actions>
      <div class="mt-4 pb-2">
        <p-button
          :disabled="isLoading || hasTransaction || isPaymentWidgetVisible"
          class="mr-3"
          secondary
          @click="backFunction"
        >
          <p-icon left>
            arrow_back
          </p-icon>
          {{ $t('core.app.back') }}
        </p-button>
        <p-button
          :disabled="isLoading || isPaymentWidgetVisible"
          @click="pay()"
        >
          {{ hasZeroCosts ? $t('core.app.send') : $t('kfmv.widget.eventregistration.payment_pay') }}
          <p-icon v-if="!hasZeroCosts" right>
            credit_card
          </p-icon>
        </p-button>
      </div>
    </template>
  </kfmv-wizard-step>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import mixins from '@glittr/frontend-core/src/mixins';
  import validatable from '@glittr/frontend-core/src/mixins/validatable/validatable';
  import OnlinePaymentContactAddressModel from '@glittr/frontend-core/src/services/v2/model/online-payment-contact-address-model';
  import BasketModel from '@/src/services/v2/model/basket-model';
  import EventModel from '@/src/services/v2/model/event-model';
  import CreateEventRegistrationRequestModel from '@/src/services/v2/model/create-event-registration-request-model';
  import OnlinePaymentOrderItemModel from '@/src/services/v2/model/online-payment-order-item-model';
  import OnlinePaymentModel from '@/src/services/v2/model/online-payment-model';
  import CreateTransactionRequestModel from '@/src/services/v2/model/create-transaction-request-model';
  import RequestSummaryModel from '@/src/services/v2/model/request-summary-model';
  import RequestSummarySectionModel from '@/src/services/v2/model/request-summary-section-model';
  import RequestSummaryItemModel from '@/src/services/v2/model/request-summary-item-model';
  import { CurrencyCodeType } from '@glittr/frontend-core/src/plugins/localization/currencyCodes';
  import GetKFMVEventStateInformationRequestModel from '@/src/services/v2/model/get-kfmv-event-state-information-request-model';
  import BasketItemModel from '@/src/services/v2/model/basket-item-model';
  import GetEventCheckoutByIdRequestModel from '@/src/services/v2/model/get-event-checkout-by-id-request-model';
  import UpdateEventCheckoutRequestModel from '@/src/services/v2/model/update-event-checkout-request-model';
  import _ from '@glittr/frontend-core/src/utils';

  const paymentStateQuery = 'paymentstate';
  type PaymentState = 'success' | 'failed';
  export default mixins(validatable).extend({
    name: 'WizardStepEventRegistrationontactData',
    props: {
      value: { type: Object, default: undefined },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      clearProgressFunction: { type: Function as PropType<(()=>Promise<void>)>, default: () => {} },
      saveFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      backFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      resetFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
    },
    data: () => ({
      isLoading: false,
      isCheckoutLoading: false,
      latestPromoCodeNotFound: false,
      promotionCode: undefined as string | undefined,
      error: undefined as any,
      eventInformation: new EventModel(),
      isPaymentWidgetVisible: false,
      isPaymentErrorVisible: false,
      termsAndConditionsError: undefined as string | undefined,
    }),

    computed: {
      isPromotionCodeInUse(): boolean {
        return !this._.isEmpty(this.wizardData.basket?.promotionCode);
      },
      hasZeroCosts(): boolean {
        if (this.eventInformation?.isFree) {
          return true;
        }
        if ((this.wizardData.basket?.grossTotal ?? 0) === 0) {
          return true;
        }
        return false;
      },
      hasTransaction(): boolean {
        return this.wizardData.basket?.onlinePayment?.transactionId !== undefined;
      },
      wizardData: {
        get(): CreateEventRegistrationRequestModel {
          return this.value ?? {};
        },
        set(value: CreateEventRegistrationRequestModel) {
          this.$emit('input', value);
        },
      },
      basketItems(): BasketItemModel[] {
        return this.wizardData?.basket?.items ?? [];
      },
      shouldCreateTransaction: {
        get(): boolean {
          return this.$sessionStorage.get<boolean>('create-transaction') ?? false;
        },
        set(value: boolean | undefined): void {
          if (value === undefined) {
            this.$sessionStorage.remove('create-transaction');
            return;
          }
          this.$sessionStorage.set('create-transaction', value);
        },
      },
    },
    async beforeMount() {
      if (this.wizardData.checkoutId === undefined) {
        // No checkout = invalid state
        // Reset the entire wizard
        this.resetFunction!();
        return;
      }
      this.isLoading = true;
      const eventInfoResponse = await Vue.$service.v2.api.events.getKFMVEventStateInformation(new GetKFMVEventStateInformationRequestModel({
        id: this.wizardData.eventId,
        language: this.wizardData.languageIso2,
        eventPartyRoleId: this.wizardData.eventPartyRoleId,
      }));
      this.eventInformation = eventInfoResponse.data;

      this.wizardData.basket = this.wizardData.basket ?? new BasketModel();
      await this.populateCheckout();
      this.wizardData.summary = this.generateSummary(this.eventInformation, this.wizardData);
      // Depending how this step was opened it has to be handled differently
      const paymentState = this.$routerUtils.getQueryParamsFromUrl(window?.location?.href)[paymentStateQuery] as PaymentState | undefined;
      // _______________________________________________________________________________________________
      if (this.hasTransaction && paymentState === 'success') {
        // Opened via: Redirect from wallee widget and payment was successful
        // Action: Go to confirmation step
        this.completeWizard();
        return;
      }
      // _______________________________________________________________________________________________
      if (paymentState === 'failed') {
        // Opened via: Redirect from wallee widget and payment failed
        // Action: Display that payment failed
        // Continuation: User can click back or pay button
        this.wizardData.basket!.onlinePayment = undefined;
        this.saveFunction!();
        this.isPaymentErrorVisible = true;
        this.isLoading = false;
        return;
      }
      // _______________________________________________________________________________________________
      if (!this.hasTransaction && !this.shouldCreateTransaction) {
        // Opened via: Either page was refreshed while being on an earlier step and previously failed payment
        //             or user used breadcrum and previously failed payment
        // Action: Create transaction, user can reinitiate payment
        this.isPaymentWidgetVisible = false;
        this.isLoading = false;
        return;
      }
      // _______________________________________________________________________________________________
      this.isLoading = false;
    },
    methods: {
      async clearPromotionCode() {
        if (this.wizardData.basket) {
          this.promotionCode = undefined;
          this.latestPromoCodeNotFound = true;
          this.wizardData.basket!.promotionCode = undefined;
          await this.populateCheckout();
        }
      },
      async completeWizard() {
        const successRedirectConfigKey = 'widget-event-registrationsuccessurl';
        const queries = Vue.$routerUtils.getQueryParams();
        const successRedirect = Vue.$config.values[successRedirectConfigKey] ?? queries?.successUrl;
        try {
          await this.$service.v2.api.events.createEventRegistration(this.wizardData);
          this.clearProgressFunction!();
          if (!successRedirect) {
            throw new Error(`No success page redirect configured, set "${successRedirectConfigKey}"`);
          }
          Vue.$routerUtils.pushRouteOrUrl(successRedirect);
        } catch (error) {
          this.error = error;
        }
      },
      generateSummary(eventInformation:EventModel, wizardData:CreateEventRegistrationRequestModel):RequestSummaryModel {
        const sections: RequestSummarySectionModel[] = [];
        // Ihre Angaben
        sections.push({
          title: this.$t('kfmv.widget.eventregistration.contactData_title'),
          items: ([
            {
              label: this.$t('kfmv.widget.eventregistration.customerNumber'),
              displayValue: wizardData.eventParticipant?.customerNumber?.toString(),
            },
            {
              label: this.$t('core.address.company'),
              displayValue: wizardData.eventParticipant?.address?.companyName,
            },
            {
              label: this.$t('core.address.salutation'),
              displayValue: wizardData.eventParticipant?.address?.salutation ? this.$t(`core.address.salutationEnum${(wizardData.eventParticipant.address.salutation) + 1}`) : undefined,
            },
            {
              label: this.$t('core.address.firstName'),
              displayValue: wizardData.eventParticipant?.address?.firstName!,
            },
            {
              label: this.$t('core.address.lastName'),
              displayValue: wizardData.eventParticipant?.address?.lastName!,
            },
            {
              label: this.$t('core.address.street'),
              displayValue: `${wizardData.eventParticipant?.address?.street!} ${wizardData.eventParticipant?.address?.houseNumber ?? ''}`,
            },
            {
              label: this.$t('core.address.poBox'),
              displayValue: wizardData.eventParticipant?.address?.poBox!,
            },
            {
              label: this.$t('core.address.addressLine1'),
              displayValue: wizardData.eventParticipant?.address?.addressLine1!,
            },
            {
              label: this.$t('core.address.postalCode'),
              displayValue: wizardData.eventParticipant?.address?.postalCode!,
            },
            {
              label: this.$t('core.address.city'),
              displayValue: wizardData.eventParticipant?.address?.city!,
            },
            {
              label: this.$t('core.address.country'),
              displayValue: wizardData.eventParticipant?.address?.countryIso2 ?? '-',
            },
            {
              label: this.$t('core.address.phoneNumber'),
              displayValue: Vue.$format.phonePretty(wizardData.eventParticipant?.address?.phoneNumber!),
            },
            {
              label: this.$t('core.address.mobilePhoneNumber'),
              displayValue: Vue.$format.phonePretty(wizardData.eventParticipant?.address?.mobilePhoneNumber!),
            },
            {
              label: this.$t('core.address.email'),
              displayValue: wizardData.eventParticipant?.address?.email!,
            },
            {
              label: this.$t('kfmv.widget.eventregistration.becameAwareBy'),
              displayValue: wizardData.becameAwareBy,
            },
            {
              label: this.$t('kfmv.widget.eventregistration.message'),
              displayValue: wizardData.message,
            },
            {
              label: this.$t('kfmv.widget.eventregistration.agreeToTermsAndConditions'),
              displayValue: wizardData.hasAgreedToTermsAndConditions ? this.$t('core.app.yes') : this.$t('core.app.no'),
            },
          ].filter((item: any) => item.label !== undefined && item.displayValue !== undefined && item.displayValue !== null) ?? []),
        } as RequestSummarySectionModel);

        // Payment and Event summary
        const eventAndPaymentSection = new RequestSummarySectionModel({
          title: this.$t('kfmv.mail.eventregistration.summary-title'),
          items: [
            {
              label: this.$t('kfmv.mail.eventregistration.summary-event-name'),
              displayValue: eventInformation.eventName?.toString(),
            },
            {
              label: this.$t('kfmv.mail.eventregistration.summary-event-date'),
              displayValue: this.$format.localDate(eventInformation.startDate!),
            },
            {
              label: this.$t('kfmv.mail.eventregistration.summary-event-time'),
              displayValue: this.$format.localTime(eventInformation.startDate!),
            },
            {
              label: this.$t('kfmv.mail.eventregistration.summary-event-location'),
              displayValue: eventInformation.location?.toString(),
            },
          ] as RequestSummaryItemModel[],
        });
        // Payment information
        const paymentSummaryItems : RequestSummaryItemModel[] = wizardData.basket?.items?.map<RequestSummaryItemModel>((x) => new RequestSummaryItemModel({
          label: x.caption,
          displayValue: this.$format.currency(x.grossPrice ?? 0, <CurrencyCodeType >x.currency ?? Vue.$config.values['widget-defaultcurrency']),
        })) as RequestSummaryItemModel[];
        paymentSummaryItems.push(new RequestSummaryItemModel({
          label: this.$t('kfmv.widget.eventregistration.summary.total'),
          displayValue: this.$format.currency(wizardData.basket?.grossTotal ?? 0, <CurrencyCodeType >wizardData.basket?.currency ?? Vue.$config.values['widget-defaultcurrency']),
        }));
        if (!_.isEmpty(wizardData.basket?.promotionCode)) {
          paymentSummaryItems.push(new RequestSummaryItemModel({
            label: this.$t('kfmv.mail.eventregistration.summary-event-promoCode'),
            displayValue: this.wizardData.basket?.promotionCode,
          }));
        }
        if (_.isSet(wizardData.basket?.onlinePayment)) {
          paymentSummaryItems.push(new RequestSummaryItemModel({
            label: this.$t('kfmv.mail.eventregistration.summary-event-paymentType'),
            displayValue: this.$t('kfmv.mail.eventregistration.summary-event-paymentType.online'),
          }));
        }
        eventAndPaymentSection.items = [...eventAndPaymentSection.items as RequestSummaryItemModel[], ...paymentSummaryItems];
        sections.push(eventAndPaymentSection);
        return new RequestSummaryModel({
          languageIso2: wizardData.languageIso2,
          subject: eventInformation.eventName,
          sections,
        });
      },
      async usePromotionCode(promotionCode?: string) {
        if (!promotionCode) {
          return;
        }
        this.wizardData.basket!.promotionCode = promotionCode;
        await this.populateCheckout();
      },
      async populateCheckout() {
        this.isCheckoutLoading = true;
        this.latestPromoCodeNotFound = false;
        // Update first to make sure checkout is up to date
        const reqUpdate = new UpdateEventCheckoutRequestModel();
        reqUpdate.checkoutRequest = this.wizardData;
        reqUpdate.eventId = this.wizardData.eventId;
        reqUpdate.id = this.wizardData.checkoutId;
        await this.$service.v2.api.events.updateEventCheckout(reqUpdate);

        // Get checkout with calculated entries
        const reqGet = new GetEventCheckoutByIdRequestModel({ eventId: this.wizardData.eventId, id: this.wizardData.checkoutId });
        const { checkoutId } = this.wizardData;
        const checkout = await this.$service.v2.api.events.getEventCheckoutById(reqGet);
        this.wizardData.basket = checkout.data.checkoutRequest?.basket;
        this.wizardData.checkoutId = checkoutId;
        this.promotionCode = this.wizardData.basket?.promotionCode;
        this.saveFunction!();
        this.latestPromoCodeNotFound = !this.wizardData.basket?.items?.some((x) => x.promotionCode === this.promotionCode);
        this.isCheckoutLoading = false;
      },
      buildRedirectUrl(paymentState: PaymentState): string {
        const queries = this.$routerUtils.getQueryParams();
        queries[paymentStateQuery] = paymentState;
        const queryString = Object.keys(queries).map((key) => `${key}=${encodeURIComponent(queries[key])}`).join('&');
        return `${window.location.pathname}?${queryString}#${this.$appId}`;
      },
      validate(): boolean {
        this.termsAndConditionsError = undefined;
        if (!this.wizardData.hasAgreedToTermsAndConditions) {
          this.$nextTick(() => {
            this.termsAndConditionsError = this.$t('core.validation.required');
          });
          return false;
        }
        return true;
      },
      async pay(): Promise<void> {
        if (!this.validate()) {
          return;
        }
        this.wizardData.summary = this.generateSummary(this.eventInformation, this.wizardData);

        if (this.hasZeroCosts) {
          this.wizardData.basket!.paymentMethod = undefined;
          this.wizardData.basket!.onlinePayment = undefined;
          this.completeWizard();
          return;
        }

        if (this.hasTransaction) {
          // Opened via: Page was refreshed while being on this payment step
          // Action: Show wallee widget again
          // Continuation: Wallee widget will redirect to this step
          this.error = undefined;
          this.isPaymentErrorVisible = false;
          this.isPaymentWidgetVisible = true;
          return;
        }
        // Opened via: User clicked on next button on summary step
        //             or clicked on pay button on this step after payment failed
        // Action: Create new transaction and show wallee widget
        // Continuation: Wallee widget will redirect to this step
        this.error = undefined;
        this.isPaymentErrorVisible = false;
        this.shouldCreateTransaction = undefined;
        this.wizardData.basket = this.wizardData.basket ?? new BasketModel();
        this.wizardData.basket.onlinePayment = this.wizardData.basket.onlinePayment ?? new OnlinePaymentModel();
        this.wizardData.basket.onlinePayment.transactionId = await this.createTransaction();
        this.wizardData.basket.paymentMethod = 6;
        this.wizardData.basket.onlinePayment.paymentDate = this.$date.now().toISOString();
        this.wizardData.basket.onlinePayment.amount = this.wizardData.basket.grossTotal;
        this.saveFunction!();
        this.$nextTick(() => {
          this.isPaymentWidgetVisible = true;
        });
      },
      async createTransaction(): Promise<string | undefined> {
        this.isLoading = true;
        let transactionId;
        try {
          const transaction = await this.convertToTransaction();
          const resp = await this.$service.v2.api.onlinePaymentTransaction.createTransaction(transaction);
          transactionId = resp.data;
        } catch (error) {
          this.error = error;
        }
        this.isLoading = false;
        return transactionId;
      },
      async convertToTransaction(): Promise<CreateTransactionRequestModel> {
        const orderItems : OnlinePaymentOrderItemModel[] = [];
        const basketItems = this.wizardData.basket?.items ?? [];
        basketItems.forEach((item) => {
          const orderItem = new OnlinePaymentOrderItemModel();
          orderItem.productId = `${item.itemNumber}`;
          orderItem.name = item.caption;
          orderItem.unitPrice = item.unitPrice;
          orderItem.quantity = item.quantity;
          orderItems.push(orderItem);
        });

        const { address } = this.wizardData.eventParticipant!;
        const billingAddress = new OnlinePaymentContactAddressModel();
        billingAddress.salutation = address?.salutation?.toString();
        billingAddress.firstName = address?.firstName ?? undefined;
        billingAddress.lastName = address?.lastName ?? undefined;
        billingAddress.email = address?.email ?? undefined;
        billingAddress.phoneNumber = address?.phoneNumber ?? undefined;
        billingAddress.mobilePhoneNumber = address?.mobilePhoneNumber ?? undefined;
        billingAddress.companyName = address?.companyName ?? undefined;
        billingAddress.street = `${address?.street ?? ''} ${address?.houseNumber ?? ''}`.trim();
        billingAddress.countryISO = address?.countryIso2 ?? undefined;
        billingAddress.zipCode = address?.postalCode ?? undefined;
        billingAddress.city = address?.city ?? undefined;

        const transaction = new CreateTransactionRequestModel();
        transaction.currency = this.wizardData.basket?.currency ?? Vue.$config.values['widget-defaultcurrency'];
        transaction.language = this.wizardData.languageIso2 ?? undefined;
        transaction.billingAddress = billingAddress;
        transaction.shippingAddress = undefined;
        transaction.orderItems = orderItems;

        return transaction;
      },
    },
  });
</script>
