// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateEventCheckoutRequestModel from '../../model/create-event-checkout-request-model';
import CreateEventCheckoutRequestDTO from '../dto/create-event-checkout-request-dto';
import dtoSchema from '../schemas/create-event-checkout-request-schema';

export default abstract class CreateEventCheckoutRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateEventCheckoutRequestDTO>) {
    super();
    if (data) {
      this.eventId = data.eventId;
      this.languageIso2 = data.languageIso2;
      this.businessUnitNumber = data.businessUnitNumber;
      this.eventPartyRoleId = data.eventPartyRoleId;
      this.customerGroupNumber = data.customerGroupNumber;
      this.id = data.id;
    }
  }
  /**
  * @type {uuid}
  */
  eventId?: string;
  /**
  * @minimum 1
  */
  languageIso2?: string;
  /**
  * @type {int32}
  */
  businessUnitNumber?: number;
  /**
  * @type {uuid}
  */
  eventPartyRoleId?: string;
  /**
  * @type {int32}
  */
  customerGroupNumber?: number;
  /**
  */
  id?: string;

  static toModel(dto: DeepPartial<CreateEventCheckoutRequestDTO>): CreateEventCheckoutRequestModel;
  static toModel(dto: DeepPartial<CreateEventCheckoutRequestDTO> | undefined | null): CreateEventCheckoutRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateEventCheckoutRequestDTO> | undefined | null): CreateEventCheckoutRequestModel | undefined {
    return dto ? new CreateEventCheckoutRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateEventCheckoutRequestModel> | CreateEventCheckoutRequestModel): CreateEventCheckoutRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      eventId: unwrapped.eventId,
      languageIso2: unwrapped.languageIso2,
      businessUnitNumber: unwrapped.businessUnitNumber,
      eventPartyRoleId: unwrapped.eventPartyRoleId,
      customerGroupNumber: unwrapped.customerGroupNumber,
      id: unwrapped.id,
    } as CreateEventCheckoutRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateEventCheckoutRequestModel, can not map to CreateEventCheckoutRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
