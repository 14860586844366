import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    ...coreRoutes,
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      component: () => import('./views/pages/app/dashboard.vue'),
    },
    {
      path: '/_widgets/event/registration',
      name: 'event-registration',
      component: () => import('./views/widgets/event-registration/event-registration.vue'),
    },
    {
      path: '/_widgets/event/search',
      name: 'event-search',
      component: () => import('./views/widgets/event-search/event-search.vue'),
    },
    {
      path: '/:tenant/:workspace/widgets/event-registration-config',
      name: 'event-registration-configuration',
      component: () => import('./views/widgets/event-registration-config/event-registration-config.vue'),
    },
    {
      path: '/_widgets/event/detail',
      name: 'event-detail',
      component: () => import('./views/widgets/event-detail/event-detail.vue'),
    },
    {
      path: '/:tenant/:workspace/widgets/event-detail-config',
      name: 'event-detail-configuration',
      component: () => import('./views/widgets/event-detail-config/event-detail-config.vue'),
    },

    {
      path: '/:tenant/:workspace/widgets/event-search-config',
      name: 'event-search-configuration',
      component: () => import('./views/widgets/event-search-config/event-search-config.vue'),
    },
  ],
};
