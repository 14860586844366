// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateEventCheckoutRequestModel from '../../model/update-event-checkout-request-model';
import UpdateEventCheckoutRequestDTO from '../dto/update-event-checkout-request-dto';
import dtoSchema from '../schemas/update-event-checkout-request-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import EventRegistrationRequestModel from '../../model/event-registration-request-model';

export default abstract class UpdateEventCheckoutRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateEventCheckoutRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.checkoutRequest = data.checkoutRequest ? EventRegistrationRequestModel.toModel(data.checkoutRequest) : undefined;
      this.eventId = data.eventId;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  checkoutRequest?: EventRegistrationRequestModel;
  /**
  * @type {uuid}
  */
  eventId?: string;

  static toModel(dto: DeepPartial<UpdateEventCheckoutRequestDTO>): UpdateEventCheckoutRequestModel;
  static toModel(dto: DeepPartial<UpdateEventCheckoutRequestDTO> | undefined | null): UpdateEventCheckoutRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateEventCheckoutRequestDTO> | undefined | null): UpdateEventCheckoutRequestModel | undefined {
    return dto ? new UpdateEventCheckoutRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateEventCheckoutRequestModel> | UpdateEventCheckoutRequestModel): UpdateEventCheckoutRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      checkoutRequest: unwrapped.checkoutRequest,
      eventId: unwrapped.eventId,
    } as UpdateEventCheckoutRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateEventCheckoutRequestModel, can not map to UpdateEventCheckoutRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
