// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContactSchema from './contact-schema';
import BasketSchema from './basket-schema';
import RequestSummarySchema from './request-summary-schema';

const schema = {
  $id: '/event-registration-request-schema',
  type: 'object',
  required: ['eventId', 'languageIso2', 'businessUnit', 'eventPartyRoleId', 'customerGroupNumber', 'eventParticipant'],
  properties: {
    eventId: {
      type: 'string',
      format: 'uuid',
    },
    languageIso2: {
      type: 'string',
      minLength: 1,
    },
    businessUnit: {
      type: 'integer',
    },
    eventPartyRoleId: {
      type: 'string',
      format: 'uuid',
    },
    eventOrderTemplate: {
      type: 'string',
      format: 'uuid',
      nullable: true,
    },
    customerGroupNumber: {
      type: 'integer',
    },
    contactAddressOwnerNumber: {
      type: 'integer',
      nullable: true,
    },
    eventParticipant: {
      ...ContactSchema,
    },
    basket: {
      ...BasketSchema,
    },
    summary: {
      ...RequestSummarySchema,
    },
    becameAwareBy: {
      type: 'string',
      nullable: true,
      maxLength: 250,
    },
    message: {
      type: 'string',
      nullable: true,
      maxLength: 2000,
    },
  },
} as IJSONSchema;

export default schema;
