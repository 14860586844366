// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/contact-address-schema',
  type: 'object',
  required: [],
  properties: {
    isCompany: {
      type: 'boolean',
    },
    companyName: {
      type: 'string',
      nullable: true,
    },
    salutation: {
      enum: [
        0,
        1,
        2,
      ],
    },
    firstName: {
      type: 'string',
      nullable: true,
    },
    lastName: {
      type: 'string',
      nullable: true,
    },
    email: {
      type: 'string',
      nullable: true,
    },
    phoneNumber: {
      type: 'string',
      nullable: true,
    },
    mobilePhoneNumber: {
      type: 'string',
      nullable: true,
    },
    companyPhone: {
      type: 'string',
      nullable: true,
    },
    street: {
      type: 'string',
      nullable: true,
    },
    houseNumber: {
      type: 'string',
      nullable: true,
    },
    addressLine1: {
      type: 'string',
      nullable: true,
    },
    addressLine2: {
      type: 'string',
      nullable: true,
    },
    poBox: {
      type: 'string',
      nullable: true,
    },
    postalCode: {
      type: 'string',
      nullable: true,
    },
    city: {
      type: 'string',
      nullable: true,
    },
    countryIso2: {
      type: 'string',
      nullable: true,
    },
    website: {
      type: 'string',
      nullable: true,
    },
    membershipNumber: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
