// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetKFMVEventStateInformationRequestModel from '../../model/get-kfmv-event-state-information-request-model';
import GetKFMVEventStateInformationRequestDTO from '../dto/get-kfmv-event-state-information-request-dto';
import dtoSchema from '../schemas/get-kfmv-event-state-information-request-schema';

export default abstract class GetKFMVEventStateInformationRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetKFMVEventStateInformationRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.language = data.language;
      this.eventPartyRoleId = data.eventPartyRoleId;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  language?: string;
  /**
  * @type {uuid}
  */
  eventPartyRoleId?: string;

  static toModel(dto: DeepPartial<GetKFMVEventStateInformationRequestDTO>): GetKFMVEventStateInformationRequestModel;
  static toModel(dto: DeepPartial<GetKFMVEventStateInformationRequestDTO> | undefined | null): GetKFMVEventStateInformationRequestModel | undefined;
  static toModel(dto: DeepPartial<GetKFMVEventStateInformationRequestDTO> | undefined | null): GetKFMVEventStateInformationRequestModel | undefined {
    return dto ? new GetKFMVEventStateInformationRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetKFMVEventStateInformationRequestModel> | GetKFMVEventStateInformationRequestModel): GetKFMVEventStateInformationRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      language: unwrapped.language,
      eventPartyRoleId: unwrapped.eventPartyRoleId,
    } as GetKFMVEventStateInformationRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetKFMVEventStateInformationRequestModel, can not map to GetKFMVEventStateInformationRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
