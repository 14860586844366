<template>
  <div class="widget event search">
    <div class="search-container">
      <p-row class="search-filter-container ">
        <p-col class="label search-text" xs12 sm10 md4 lg3>
          <p-text-field v-model="vm.eventsDataSource.filter.searchCriteria" type="search" :label="$t('core.app.search')"></p-text-field>
        </p-col>
        <p-col class="label search-date-from" xs12 sm5 md3 lg2>
          <p-date-field v-model="vm.eventsDataSource.filter.dateRangeFrom" :label="$t('widget.event-search.filter.date-from.label')" clearable></p-date-field>
        </p-col>
        <p-col class="label search-date-to" xs12 sm5 md3 lg2>
          <p-date-field v-model="vm.eventsDataSource.filter.dateRangeTo" :label="$t('widget.event-search.filter.date-to.label')" clearable></p-date-field>
        </p-col>
      </p-row>
      <p-row class="search-action-container ">
        <p-col xs12 sm10 md10 lg7 align-end>
          <p-row justify-end align-end>
            <p-button secondary @click="vm.clearFilter()">
              <p-icon left>
                clear
              </p-icon>
              {{ $t('core.app.delete') }}
            </p-button>
            <p-button @click="vm.populateData()">
              {{ $t('core.app.search') }}
            </p-button>
          </p-row>
        </p-col>
      </p-row>
    </div>
    <div class="search-result-container">
      <p-table class="search-result-table mt-4" :value="vm.eventsTableData" :columns="vm.eventsTableColumns" :loading="vm.eventsDataSource.isLoading">
        <template #eventName="{ entry, row }">
          <p-conditional-wrapper :show="vm.eventDetailUrl">
            <a class="text-color-text" target="_blank" rel="noopener noreferrer" :href="getEventUrl(row.values.id)" :title="entry.value">
              <span>{{ $format.ellipsis(entry.value, 80) }}</span>
            </a>
          </p-conditional-wrapper>
          <p-button v-if="vm.eventDetailUrl" tertiary dense @click="openEventDetail(row.values.id)">
            <p-icon dense color="interaction">
              open_in_new
            </p-icon>
          </p-button>
        </template>
        <template #description="{ entry }">
          <span :title="entry.value">{{ $format.ellipsis(entry.value, 25) }}</span>
        </template>
        <template #id="{ entry, rowIndex }">
          <div :id="`table-data-event-id-${rowIndex}`">
            <span :ref="`tableDataEventId${rowIndex}`" class="no-wrap">{{ entry.value }}</span>
          </div>
        </template>
        <template #action="{ rowIndex }">
          <p-button dense secondary @click="copyId(rowIndex)">
            <p-icon dense>
              content_copy
            </p-icon>
          </p-button>
        </template>
      </p-table>
    </div>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import EventSearchVM from './event-search.vue.model';

  export default Vue.extend({
    name: 'WizardEventSearch',
    layout: 'layout-widget',
    props: {},
    data: () => ({
      vm: new EventSearchVM(),
    }),
    async mounted() {
      await this.vm.initialize();
    },
    methods: {
      getEventUrl(eventId: number) {
        const hasQuery = !!this.vm.eventDetailUrl?.match(/\?\w/) ?? false;
        const eventUrl = this.vm.eventDetailUrl?.replace(/\?(eventId=)?$/i, '') ?? '';
        const queryDelimiter = hasQuery ? '&' : '?';
        return `${eventUrl + queryDelimiter}eventid=${eventId}`;
      },
      openEventDetail(eventId: number) {
        window.open(this.getEventUrl(eventId), '_blank');
      },
      copyId(index: number) {
        const ref = this.$refs[`tableDataEventId${index}`] as Element;
        this._.copyToClipboard(ref);
      },
    },
  });
</script>
