// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import BasketModel from '../../model/basket-model';
import BasketDTO from '../dto/basket-dto';
import dtoSchema from '../schemas/basket-schema';
import BasketItemModel from '../../model/basket-item-model';
import OnlinePaymentModel from '../../model/online-payment-model';

export default abstract class BasketModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<BasketDTO>) {
    super();
    if (data) {
      this.membershipTemplateId = data.membershipTemplateId ?? undefined;
      this.grossTotal = data.grossTotal ?? undefined;
      this.currency = data.currency ?? undefined;
      this.items = data.items?.map((i) => BasketItemModel.toModel(i)) ?? [];
      this.periodStart = data.periodStart ?? undefined;
      this.periodEnd = data.periodEnd ?? undefined;
      this.paymentMethod = data.paymentMethod;
      this.promotionCode = data.promotionCode ?? undefined;
      this.onlinePayment = data.onlinePayment ? OnlinePaymentModel.toModel(data.onlinePayment) : undefined;
    }
  }
  /**
  * @type {uuid}
  */
  membershipTemplateId?: string;
  /**
  * @type {double}
  */
  grossTotal?: number;
  /**
  */
  currency?: string;
  /**
  */
  items?: BasketItemModel[];
  /**
  * @type {date-time}
  */
  periodStart?: string;
  /**
  * @type {date-time}
  */
  periodEnd?: string;
  /**
  * @type {int32}
  */
  paymentMethod?: 1 | 2 | 3 | 6 | 7 | 8; // PaymentMethodDTO
  /**
  */
  promotionCode?: string;
  /**
  */
  onlinePayment?: OnlinePaymentModel;

  static toModel(dto: DeepPartial<BasketDTO>): BasketModel;
  static toModel(dto: DeepPartial<BasketDTO> | undefined | null): BasketModel | undefined;
  static toModel(dto: DeepPartial<BasketDTO> | undefined | null): BasketModel | undefined {
    return dto ? new BasketModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<BasketModel> | BasketModel): BasketDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      membershipTemplateId: unwrapped.membershipTemplateId,
      grossTotal: unwrapped.grossTotal,
      currency: unwrapped.currency,
      items: unwrapped.items,
      periodStart: unwrapped.periodStart,
      periodEnd: unwrapped.periodEnd,
      paymentMethod: unwrapped.paymentMethod,
      promotionCode: unwrapped.promotionCode,
      onlinePayment: unwrapped.onlinePayment,
    } as BasketDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for BasketModel, can not map to BasketDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
