<template>
  <kfmv-wizard-step class="wizard-step-create-event-registration" :proceed-disabled="isLoadingCheckout" :custom-proceed-function="customProceed" :errors="validationError">
    <p-request-alert v-model="error"></p-request-alert>
    <p-row>
      <p-col>
        <p-col v-if="isLoading">
          <p-progress-linear class="my-5" indeterminate></p-progress-linear>
        </p-col>
        <p-col v-else class="eventregistration-price-selection" :class="classList">
          <p-row>
            <p-col>
              <p-radio-field
                v-model="wizardData.customerGroupNumber"
                :true-value="CUSTOMER_GROUP_NUMBER_MEMBER"
                name="customerGroupNumber"
                :label="`${$t('kfmv.widget.eventregistration.priceSelection.IAmMember')}: ${$format.currency(eventInfo.memberPriceItem.price, eventInfo.memberPriceItem.currency)}`"
              ></p-radio-field>
            </p-col>
          </p-row>
          <p-row>
            <p-col>
              <p-radio-field
                v-model="wizardData.customerGroupNumber"
                :true-value="CUSTOMER_GROUP_NUMBER_NON_MEMBER"
                name="customerGroupNumber"
                :label="`${$t('kfmv.widget.eventregistration.priceSelection.INotAMember')}: ${$format.currency(eventInfo.nonMemberPriceItem.price, eventInfo.memberPriceItem.currency)}`"
              ></p-radio-field>
            </p-col>
          </p-row>
          <p-row v-if="isLoadingCheckout">
            <p-col>
              <p-progress-linear indeterminate></p-progress-linear>
            </p-col>
          </p-row>
        </p-col>
      </p-col>
    </p-row>
  </kfmv-wizard-step>
</template>

<script lang="ts">
  import { PropType } from 'vue';
  import mixins from '@glittr/frontend-core/src/mixins';
  import validatable from '@glittr/frontend-core/src/mixins/validatable/validatable';
  import CreateEventRegistrationRequestModel from '@/src/services/v2/model/create-event-registration-request-model';
  import EventModel from '@/src/services/v2/model/event-model';
  import GetKFMVEventStateInformationRequestModel from '@/src/services/v2/model/get-kfmv-event-state-information-request-model';
  import CreateEventCheckoutRequestModel from '@/src/services/v2/model/create-event-checkout-request-model';
  import BasketItemModel from '@/src/services/v2/model/basket-item-model';

  export default mixins(validatable).extend({
    name: 'WizardStepEventRegistrationPriceSelection',
    props: {
      value: { type: CreateEventRegistrationRequestModel, default: undefined },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      clearProgressFunction: { type: Function as PropType<(()=>Promise<void>)>, default: () => {} },
      saveFunction: { type: Function as PropType<(()=>Promise<void>)>, default: () => {} },
    },
    data: () => ({
      isLoading: false,
      isLoadingCheckout: false,
      error: undefined as any,
      validationError: undefined as string | undefined,
      orderItems: [] as BasketItemModel[],
      eventInfo: undefined as EventModel | undefined,
      CUSTOMER_GROUP_NUMBER_NON_MEMBER: EventModel.CUSTOMER_GROUP_NUMBER_NON_MEMBER,
      CUSTOMER_GROUP_NUMBER_MEMBER: EventModel.CUSTOMER_GROUP_NUMBER_MEMBER,
    }),
    watch: {
      'wizardData.customerGroupNumber': {
        immediate: true,
        async handler() {
          if (this.wizardData.customerGroupNumber) {
            this.wizardData.checkoutId = await this.createOrUpdateCheckout();
          }
        },
      },
    },
    computed: {
      classList(): Record<string, boolean> {
        return {
          'input-error': this._.isSet(this.validationError),
        };
      },
      wizardData: {
        get(): CreateEventRegistrationRequestModel {
          return this.value ?? {} as CreateEventRegistrationRequestModel;
        },
        set(value: CreateEventRegistrationRequestModel) {
          this.$emit('input', value);
        },
      },
    },
    async beforeMount() {
      this.isLoading = true;
      try {
        const eventInfoResponse = await this.$service.v2.api.events.getKFMVEventStateInformation(new GetKFMVEventStateInformationRequestModel({
          id: this.wizardData.eventId,
          language: this.wizardData.languageIso2,
          eventPartyRoleId: this.wizardData.eventPartyRoleId,
        }));
        this.eventInfo = eventInfoResponse.data;
      } catch (error: any) {
        this.error = error;
        this.$log.error(error);
      }
      this.isLoading = false;
    },
    methods: {
      async createOrUpdateCheckout(): Promise<string> {
        this.isLoadingCheckout = true;

        // CREATE
        const checkoutRequest = new CreateEventCheckoutRequestModel();
        checkoutRequest.businessUnitNumber = this.wizardData.businessUnit;
        checkoutRequest.customerGroupNumber = this.wizardData.customerGroupNumber;
        checkoutRequest.eventId = this.wizardData.eventId;
        checkoutRequest.eventPartyRoleId = this.wizardData.eventPartyRoleId;
        checkoutRequest.languageIso2 = this.wizardData.languageIso2;
        checkoutRequest.id = this.wizardData.eventId;
        const checkout = await this.$service.v2.api.events.createEventCheckout(checkoutRequest);
        this.wizardData.basket = checkout.data.checkoutRequest?.basket;
        this.wizardData.checkoutId = checkout.data.id;
        this.saveFunction!();
        this.isLoadingCheckout = false;
        return checkout.data.id!;
      },
      validate(): boolean {
        if (this._.isEmpty(this.wizardData.customerGroupNumber) && !this.eventInfo?.isFree) {
          this.validationError = this.$t('kfmv.widget.eventregistration.priceselection_error');
          return false;
        }
        return true;
      },
      customProceed() {
        if (this.validate()) {
          this.proceedFunction!();
        }
      },
    },
  });
</script>
