<template>
  <p-select
    :value="value"
    :items="items"
    v-bind="$props"
    :required="required"
    @input="$emit('input', $event)"
    v-on="$listeners"
  ></p-select>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      value: { default: undefined, type: undefined },
      disabled: { default: false, type: Boolean },
      readonly: { default: false, type: Boolean },
      solo: { default: false, type: Boolean },
      placeholder: { default: undefined, type: String },
      label: { default: undefined, type: String },
      required: { default: false, type: Boolean },
      caption: { default: undefined, type: String },
    },
    data: () => ({
      items: [
        { caption: Vue.$t('core.country.switzerland'), id: 'CH' },
        { caption: Vue.$t('core.country.germany'), id: 'DE' },
        { caption: Vue.$t('core.country.austria'), id: 'AT' },
        { caption: Vue.$t('core.country.italy'), id: 'IT' },
        { caption: Vue.$t('core.country.france'), id: 'FR' },
      ],
    }),
    watch: {
      value: {
        handler() {
          this.$emit('update:caption', this.items.find((country) => country.id === this.value)?.caption ?? '-');
        },
      },
    },
    mounted() {
      if (!this.value) {
        this.$emit('input', 'CH');
      }
      this.$emit('update:caption', this.items.find((country) => country.id === this.value)?.caption ?? '-');
    },
  });
</script>
