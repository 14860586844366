import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateEventRegistrationRequestDTO from '../_generated/dto/create-event-registration-request-dto';
import CreateEventRegistrationRequestModelBase from '../_generated/modelBases/create-event-registration-request-model-base';
import EventModel from './event-model';

export default class CreateEventRegistrationRequestModel extends CreateEventRegistrationRequestModelBase {
  constructor(data?: DeepPartial<CreateEventRegistrationRequestDTO> & { checkoutId?: string, hasAgreedToTermsAndConditions?: boolean }) {
    super(data);
    this.checkoutId = data?.checkoutId ?? undefined;
    this.hasAgreedToTermsAndConditions = data?.hasAgreedToTermsAndConditions ?? undefined;
  }

  checkoutId?: string = undefined;

  hasAgreedToTermsAndConditions?: boolean = undefined;

  get isMember(): boolean {
    return this.customerGroupNumber === EventModel.CUSTOMER_GROUP_NUMBER_MEMBER;
  }

  get isNonMember(): boolean {
    return !this.isMember;
  }
}
