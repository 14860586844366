import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import Vue from 'vue';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import _ from '@glittr/frontend-core/src/utils';

export default class EventSearchVM extends PageBase {
  get language(): string { return Vue.$config.getQueryOrValue('language', 'language') ?? 'de'; }

  get businessUnitNumber(): number | undefined {
    const val = Vue.$config.getQueryOrValue('widget-event-businessunitnumber', 'businessUnit');
    if (val === 'undefined') return undefined;
    return _.isSet(val) ? Number.parseInt(val, 10) : undefined;
  }

  get eventDetailUrl(): string | undefined {
    const val = Vue.$config.getQueryOrValue('widget-event-eventdetailurl', 'eventDetailUrl');
    if (val === 'undefined') return undefined;
    return val ?? undefined;
  }

  get eventsTableColumns() {
    return [
      { field: 'eventName', value: Vue.$t('widget.event-search.event-table.header.title'), width: '50%' },
      {
        field: 'startDate', value: Vue.$t('widget.event-search.event-table.header.begin'), width: '50%', format: (v: string) => Vue.$format.localDate(v),
      },
      { field: 'id', value: 'ID' },
      { field: 'action', value: '' },
    ];
  }

  get eventsTableData() { return this.eventsDataSource.data?.items.map((i) => ({ ...i.data })) ?? []; }

  public eventsDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.events.getEventsList,
  });

  public clearFilter(): void {
    // Can't clear businessUnitNumber as it's fixed to the current business unit
    // this.eventsDataSource.filter.businessUnitNumber = undefined;
    this.eventsDataSource.filter.dateRangeFrom = undefined;
    this.eventsDataSource.filter.dateRangeTo = undefined;
    this.eventsDataSource.filter.eventCategoryId = undefined;
    this.eventsDataSource.filter.eventGroupingNumber = undefined;
    this.eventsDataSource.filter.eventPlace = undefined;
    this.eventsDataSource.filter.eventTargetAudienceId = undefined;
    this.eventsDataSource.filter.isEventPublished = undefined;
    this.eventsDataSource.filter.searchCriteria = undefined;
  }

  public populateData() {
    this.eventsDataSource.select();
  }

  async initialize(): Promise<void> {
    this.eventsDataSource.filter.businessUnitNumber = this.businessUnitNumber;
    if (this.eventsDataSource.filter.businessUnitNumber === undefined) {
      throw new Error('BusinessUnitNumber is not set');
    }
    this.eventsDataSource.filter.dateRangeFrom = Vue.$date.now().format('YYYY-MM-DD');
  }
}
