<template>
  <p-child nested namespace="kfmv-wizard" class="kfmv-wizard-step">
    <slot></slot>
    <p-checkbox
      v-if="stepData.hasTermsAndConditions"
      v-slot="{ label, checked }"
      v-model="acceptedTermsAndConditions"
      class="mt-7"
      label="confirmAGB"
      required
    >
      <span v-html="$t('kfmv.eventRegistration.agb')"></span>
    </p-checkbox>
    <div class="mt-4">
      <p-divider></p-divider>
      <p-row>
        <p-col shrink class="pr-4">
          <slot name="step-actions" :is-loading="isLoading" :proceed-disabled="proceedDisabled">
            <div class="mt-4 pb-2">
              <slot name="back-action">
                <p-button :disabled="isLoading" :loading="isLoading" class="mr-3" secondary @click="goBack">
                  <p-icon left>
                    arrow_back
                  </p-icon>{{ $t('core.app.back') }}
                </p-button>
              </slot>
              <p-button
                :loading="isLoading"
                :disabled="proceedDisabled || isLoading || (stepData.hasTermsAndConditions && !acceptedTermsAndConditions)"
                class="proceed"
                @click="proceedOrComplete"
              >
                {{ proceedLabelComputed }}
                <p-icon right>
                  {{ stepData.sendData ? 'send' : 'arrow_forward' }}
                </p-icon>
              </p-button>
            </div>
          </slot>
        </p-col>
        <p-col grow>
          <p-request-alert v-model="errorResponse" :dismissable="false"></p-request-alert>
        </p-col>
      </p-row>
    </div>
  </p-child>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { StepData } from './kfmv-wizard.vue';

  export default Vue.extend({
    props: {
      value: { type: Object, default: undefined },
      loading: { type: Boolean, default: false },
      proceedDisabled: { type: Boolean, default: false },
      proceedLabel: { type: String, default: undefined },
      customProceedFunction: { type: Function, default: undefined },
      customBackFunction: { type: Function, default: undefined },
      errors: { type: String, default: undefined },
    },
    data: () => ({
      stepData: {} as Partial<StepData>,
      // eslint-disable-next-line no-unused-vars
      proceedFunction: undefined as undefined | (() => void),

      saveFunction: undefined as undefined | (() => void),
      validateFunction: undefined as undefined | (()=>boolean),
      backFunction: undefined as undefined | (() => void),
      // eslint-disable-next-line no-unused-vars
      completeFunction: undefined as undefined | (() => void),
      isLastStep: false,
      isFirstStep: false,
      errorResponse: undefined as any,
      internalLoading: false,
      sendData: false,
      acceptedTermsAndConditions: false,
    }),
    computed: {
      isLoading: {
        get(): boolean {
          return this.internalLoading || this.loading;
        },
        set(value: boolean) {
          this.internalLoading = value;
        },
      },
      proceedLabelComputed(): string {
        const defaultLabel = this.stepData.sendData ? this.$t('core.app.send') : this.$t('app.continue');
        return this.proceedLabel ?? defaultLabel;
      },
    },
    watch: {
      errors() {
        this.errorResponse = this.errors;
      },
    },
    methods: {
      goBack() {
        if (typeof this.customBackFunction === 'function') {
          this.customBackFunction();
        } else if (this.backFunction) {
          this.backFunction();
        }
      },
      proceedOrComplete() {
        if (this.isLastStep) {
          this.complete();
        } else {
          this.proceed();
        }
      },
      async proceed() {
        if (typeof this.customProceedFunction === 'function') {
          this.isLoading = true;
          try {
            const result = this.customProceedFunction();
            if (this._.isPromise(result)) {
              await result;
            }
          } catch (error: any) {
            this.$log.error(error);
            this.errorResponse = error;
          }
          this.isLoading = false;
        } else if (typeof this.proceedFunction === 'function') {
          this.proceedFunction();
        }
      },
      async complete() {
        if (typeof this.customProceedFunction === 'function') {
          this.isLoading = true;
          try {
            const result = this.customProceedFunction();
            if (this._.isPromise(result)) {
              await result;
            }
          } catch (error: any) {
            this.$log.error(error);
            this.errorResponse = error;
          }
          this.isLoading = false;
        } else if (typeof this.completeFunction === 'function') {
          this.completeFunction();
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  .kfmv-wizard-step{
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .p-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
</style>
